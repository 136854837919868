
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>

                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :limit-text="limitText"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        track-by="branchId"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label class="d-inline align-items-center"
                        >ปี (ค.ศ):</label
                      >
                      <multiselect
                        v-model="filter.year"
                        :options="yearOption"
                        :label="index"
                        :show-labels="false"
                        :class="{
                          'is-invalid': submitform && $v.filter.year.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.filter.year.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.filter.year.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center">เดือน :</label>
                      <multiselect
                        v-model="filter.month"
                        :options="optionMonth"
                        label="name"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label class="d-inline align-items-center"
                        >รูปแบบการแสดง :</label
                      >
                      <multiselect
                        v-model="filter.listType"
                        :options="optionListType"
                        label="nameTh"
                        :show-labels="false"
                        :class="{
                          'is-invalid': submitform && $v.filter.listType.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.filter.listType.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.filter.listType.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <btnComponent
                      @click="validateForm(branchIdSearch)"
                      changeStyle="search"
                    >
                    </btnComponent>
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <!-- type U -->
                <div class="table-responsive">
                  <table
                    class="table table-bordered table-sortable"
                    v-if="filter.listType.id == 'U'"
                  >
                    <thead>
                      <!-- <i
                            class="bx bxs-sort-alt sort-by float-end"
                          ></i> -->
                      <tr class="table-active">
                        <th @click="sort('loadnApplications')" colspan="3">
                          จำนวนขอนุมัติ
                        </th>
                        <th @click="sort('prospectCustomers')" colspan="4">
                          จำนวนลูกค้ามุ่งหวัง
                        </th>
                        <th @click="sort('lostProspectCustomers')" colspan="4">
                          จำนวนลูกค้ามุ่งหวัง(lost)
                        </th>
                        <th @click="sort('vehicleSales')" colspan="3">
                          จำนวนการขาย
                        </th>
                        <th @click="sort('branchNameTh')" colspan="4">สาขา</th>
                      </tr>
                    </thead>
                    <tbody v-for="(value, index) in rowsData" :key="index">
                      <tr>
                        <td colspan="3" class="text-end">
                          {{ value.loadnApplications }}
                        </td>
                        <td colspan="4" class="text-end">
                          {{ value.prospectCustomers }}
                        </td>
                        <td colspan="4" class="text-end">
                          {{ value.lostProspectCustomers }}
                        </td>
                        <td colspan="3" class="text-end">
                          {{ value.vehicleSales }}
                        </td>
                        <td colspan="4">{{ value.branchNameTh }}</td>
                      </tr>

                      <tr class="table-active">
                        <th class="align-middle">ลำดับ</th>
                        <th
                          @click="sort('salesPerson')"
                          class="textconvert align-middle"
                          scope="col"
                        >
                          พนักงานขาย
                        </th>

                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ยอดลูกค้ามุ่งหวัง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ยอดลูกค้ามุ่งหวังเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          Lost
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % Lost
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % Lost เทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('vehicleSales')"
                          class="textconvert align-middle"
                        >
                          ยอดจอง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % จอง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % จองเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ขออนุมัติไฟแนนซ์
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ไฟแนนซ์อนุมัติ
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ไฟแนนซ์อนุมัติ
                        </th>

                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ไฟแนนซ์อนุมัติเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ยอดขาย
                        </th>
                        <th
                          @click="sort('loadnApplications')"
                          class="textconvert align-middle"
                        >
                          % ขาย
                        </th>
                        <th
                          @click="sort('prospectCustomers')"
                          class="textconvert align-middle"
                        >
                          % ขายทั้งสาขา
                        </th>
                        <th
                          @click="sort('lostProspectCustomers')"
                          class="textconvert align-middle"
                        >
                          % ยอดขายเทียบยอดจอง
                        </th>
                      </tr>
                      <tr
                        v-for="(ele, index) in value.users"
                        :key="'ele' + index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td style="width: 250px">{{ ele.salesPerson }}</td>

                        <td class="text-end">{{ ele.prospectCustomers }}</td>
                        <td class="text-end">
                          {{ ele.prospectCustomerBranchPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomers }}
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomerPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomerBranchPercent }}%
                        </td>
                        <td class="text-end">{{ ele.bookings }}</td>

                        <td class="text-end">{{ ele.bookingPercent }}%</td>
                        <td class="text-end">
                          {{ ele.bookingBranchPercent }}%
                        </td>
                        <td class="text-end">{{ ele.loanApplications }}</td>
                        <td class="text-end">
                          {{ ele.approvedLoanApplications }}
                        </td>
                        <td class="text-end">
                          {{ ele.approvedLoanApplicationPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.approvedLoanApplicationBranchPercent }}%
                        </td>
                        <td class="text-end">{{ ele.vehicleSales }}</td>
                        <td class="text-end">{{ ele.vehicleSalePercent }}%</td>

                        <td class="text-end">
                          {{ ele.vehicleSaleBranchPercent }}%
                        </td>

                        <td class="text-end">
                          {{ ele.vehicleSalePercentByBooking }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- debug: sort={{ currentSort }}, dir={{ currentSortDir }} -->
                </div>
                <!-- type T -->
                <div class="table-responsive">
                  <table
                    class="table table-bordered table-sortable"
                    v-if="filter.listType ? filter.listType.id == 'T' : ''"
                  >
                    <thead>
                      <tr class="table-active">
                        <th @click="sort('loadnApplications')" colspan="3">
                          จำนวนขอนุมัติ
                        </th>
                        <th @click="sort('prospectCustomers')" colspan="4">
                          จำนวนลูกค้ามุ่งหวัง
                        </th>
                        <th @click="sort('lostProspectCustomers')" colspan="4">
                          จำนวนลูกค้ามุ่งหวัง(lost)
                        </th>
                        <th @click="sort('vehicleSales')" colspan="3">
                          จำนวนการขาย
                        </th>
                        <th @click="sort('branchNameTh')" colspan="4">สาขา</th>
                      </tr>
                    </thead>
                    <tbody v-for="(value, index) in rowsData" :key="index">
                      <tr>
                        <td colspan="3" class="text-end">
                          {{ value.loadnApplications }}
                        </td>
                        <td colspan="4" class="text-end">
                          {{ value.prospectCustomers }}
                        </td>
                        <td colspan="4" class="text-end">
                          {{ value.lostProspectCustomers }}
                        </td>
                        <td colspan="3" class="text-end">
                          {{ value.vehicleSales }}
                        </td>
                        <td colspan="4">{{ value.branchNameTh }}</td>
                      </tr>
                      <tr>
                        <th colspan="18">ไม่มีทีม</th>
                      </tr>
                      <tr class="table-active">
                        <th class="align-middle">ลำดับ</th>
                        <th
                          @click="sort('salesPerson')"
                          class="align-middle"
                          scope="col"
                        >
                          สมาชิก
                        </th>

                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ยอดลูกค้ามุ่งหวัง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ยอดลูกค้ามุ่งหวังเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          Lost
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % Lost
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % Lost เทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('vehicleSales')"
                          class="textconvert align-middle"
                        >
                          ยอดจอง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % จอง
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % จองเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ขออนุมัติไฟแนนซ์
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ไฟแนนซ์อนุมัติ
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ไฟแนนซ์อนุมัติ
                        </th>

                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          % ไฟแนนซ์อนุมัติเทียบทั้งสาขา
                        </th>
                        <th
                          @click="sort('branchNameTh')"
                          class="textconvert align-middle"
                        >
                          ยอดขาย
                        </th>
                        <th
                          @click="sort('loadnApplications')"
                          class="textconvert align-middle"
                        >
                          % ขาย
                        </th>
                        <th
                          @click="sort('prospectCustomers')"
                          class="textconvert align-middle"
                        >
                          % ขายทั้งสาขา
                        </th>
                        <th
                          @click="sort('lostProspectCustomers')"
                          class="textconvert align-middle"
                        >
                          % ยอดขายเทียบยอดจอง
                        </th>
                      </tr>

                      <!-- <tr>
                        <th colspan="18">สมาชิก</th>
                      </tr> -->
                      <tr
                        v-for="(ele, index) in value.noTeam
                          ? value.noTeam.members
                          : ''"
                        :key="'eleNM' + index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ ele.salesPerson }}</td>

                        <td class="text-end">{{ ele.prospectCustomers }}</td>
                        <td class="text-end">
                          {{ ele.prospectCustomerBranchPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomers }}
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomerPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.lostProspectCustomerBranchPercent }}%
                        </td>

                        <td class="text-end">{{ ele.bookings }}</td>
                        <td class="text-end">{{ ele.bookingPercent }}%</td>
                        <td class="text-end">
                          {{ ele.bookingBranchPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.loanApplications }}
                        </td>
                        <td class="text-end">
                          {{ ele.approvedLoanApplications }}
                        </td>

                        <td class="text-end">
                          {{ ele.approvedLoanApplicationPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.approvedLoanApplicationBranchPercent }}%
                        </td>
                        <td class="text-end">{{ ele.vehicleSales }}</td>
                        <td class="text-end">
                          {{ ele.vehicleSalePercent }}
                        </td>
                        <td class="text-end">
                          {{ ele.vehicleSaleBranchPercent }}%
                        </td>
                        <td class="text-end">
                          {{ ele.vehicleSalePercentByBooking }}%
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <th>รวม</th>

                        <th class="text-end">
                          {{
                            value.noTeam ? value.noTeam.prospectCustomers : 0
                          }}
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.prospectCustomerBranchPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.lostProspectCustomers
                              : 0
                          }}
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.lostProspectCustomerPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.lostProspectCustomerBranchPercent
                              : 0
                          }}%
                        </th>

                        <th class="text-end">
                          {{ value.noTeam ? value.noTeam.bookings : 0 }}
                        </th>
                        <th class="text-end">
                          {{ value.noTeam ? value.noTeam.bookingPercent : 0 }}%
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.bookingBranchPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{ value.noTeam ? value.noTeam.loanApplications : 0 }}
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.approvedLoanApplications
                              : 0
                          }}
                        </th>

                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.approvedLoanApplicationPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam
                                  .approvedLoanApplicationBranchPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{ value.noTeam ? value.noTeam.vehicleSales : 0 }}
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam ? value.noTeam.vehicleSalePercent : ""
                          }}
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.vehicleSaleBranchPercent
                              : 0
                          }}%
                        </th>
                        <th class="text-end">
                          {{
                            value.noTeam
                              ? value.noTeam.vehicleSalePercentByBooking
                              : 0
                          }}%
                        </th>
                      </tr>
                      <tr>
                        <th colspan="18">
                          ทีม
                          <span
                            >(
                            {{ value.teams ? value.teams.length : "" }})</span
                          >
                        </th>
                      </tr>

                      <tr>
                        <td colspan="18" class="p-0 m-0">
                          <table class="table table-bordered mb-0">
                            <thead>
                              <tr class="table-active">
                                <th class="align-middle">ลำดับ</th>
                                <th
                                  @click="sort('salesPerson')"
                                  class="textconvert align-middle"
                                >
                                  ชื่อทีม/สมาชิก
                                </th>

                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  ยอดลูกค้ามุ่งหวัง
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % ยอดลูกค้ามุ่งหวังเทียบทั้งสาขา
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  Lost
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % Lost
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % Lost เทียบทั้งสาขา
                                </th>
                                <th
                                  @click="sort('vehicleSales')"
                                  class="textconvert align-middle"
                                >
                                  ยอดจอง
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % จอง
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % จองเทียบทั้งสาขา
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  ขออนุมัติไฟแนนซ์
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  ไฟแนนซ์อนุมัติ
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % ไฟแนนซ์อนุมัติ
                                </th>

                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  % ไฟแนนซ์อนุมัติเทียบทั้งสาขา
                                </th>
                                <th
                                  @click="sort('branchNameTh')"
                                  class="textconvert align-middle"
                                >
                                  ยอดขาย
                                </th>
                                <th
                                  @click="sort('loadnApplications')"
                                  class="textconvert align-middle"
                                >
                                  % ขาย
                                </th>
                                <th
                                  @click="sort('prospectCustomers')"
                                  class="textconvert align-middle"
                                >
                                  % ขายทั้งสาขา
                                </th>
                                <th
                                  @click="sort('lostProspectCustomers')"
                                  class="textconvert align-middle"
                                >
                                  % ยอดขายเทียบยอดจอง
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="(el, index) in value.teams"
                              :key="'elT' + index"
                            >
                              <tr>
                                <td class="table-primary">{{ index + 1 }}</td>
                                <th colspan="17" class="table-primary">
                                  {{ el.name }}
                                </th>
                              </tr>
                              <tr
                                v-for="(elem, index) in el.members"
                                :key="'elTm' + index"
                              >
                                <td></td>
                                <td>{{ index + 1 }}) {{ elem.salesPerson }}</td>

                                <td class="text-end">
                                  {{ elem.prospectCustomers }}
                                </td>
                                <td class="text-end">
                                  {{ elem.prospectCustomerBranchPercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.lostProspectCustomers }}
                                </td>
                                <td class="text-end">
                                  {{ elem.lostProspectCustomerPercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.lostProspectCustomerBranchPercent }}%
                                </td>

                                <td class="text-end">
                                  {{ elem.bookings }}
                                </td>
                                <td class="text-end">
                                  {{ elem.bookingPercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.bookingBranchPercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.loanApplications }}
                                </td>
                                <td class="text-end">
                                  {{ elem.approvedLoanApplications }}
                                </td>

                                <td class="text-end">
                                  {{ elem.approvedLoanApplicationPercent }}%
                                </td>
                                <td class="text-end">
                                  {{
                                    elem.approvedLoanApplicationBranchPercent
                                  }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.vehicleSales }}
                                </td>
                                <td class="text-end">
                                  {{ elem.vehicleSalePercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.vehicleSaleBranchPercent }}%
                                </td>
                                <td class="text-end">
                                  {{ elem.vehicleSalePercentByBooking }}%
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <th>รวม</th>
                                <th class="text-end">
                                  {{ el.prospectCustomers }}
                                </th>
                                <th class="text-end">
                                  {{ el.prospectCustomerBranchPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.lostProspectCustomers }}
                                </th>
                                <th class="text-end">
                                  {{ el.lostProspectCustomerPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.lostProspectCustomerBranchPercent }}%
                                </th>

                                <th class="text-end">
                                  {{ el.bookings }}
                                </th>
                                <th class="text-end">
                                  {{ el.bookingPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.bookingBranchPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.loanApplications }}
                                </th>
                                <th class="text-end">
                                  {{ el.approvedLoanApplications }}
                                </th>

                                <th class="text-end">
                                  {{ el.approvedLoanApplicationPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.approvedLoanApplicationBranchPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.vehicleSales }}
                                </th>
                                <th class="text-end">
                                  {{
                                    el.vehicleSalePercent
                                      ? el.vehicleSalePercent + "%"
                                      : 0
                                  }}
                                </th>
                                <th class="text-end">
                                  {{ el.vehicleSaleBranchPercent }}%
                                </th>
                                <th class="text-end">
                                  {{ el.vehicleSalePercentByBooking }}%
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <!-- <tr
                        v-for="(el, index) in value.teams"
                        :key="'elT' + index"
                      >
                        <td
                          v-for="(elee, index) in el.members"
                          :key="'elTM' + index"
                        >
                          elee
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                  <!-- debug: sort={{ currentSort }}, dir={{ currentSortDir }} -->
                </div>
                <br />

                <div class="row">
                  <span
                    v-if="this.rowData.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
// import ChangeDate from "@/components/changeDateComponent";
import { required } from "vuelidate/lib/validators";

// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: "KPI ทีม",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    btnComponent,
    Multiselect,
    // ChangeDate,
  },

  data() {
    return {
      loading: undefined,
      isLoading: false,

      title: "KPI ทีม",
      items: [
        {
          text: "วิเคราะห์ข้อมูล",
          active: true,
        },
        {
          text: "KPI ทีม",
          active: true,
        },
      ],
      optionMonth: [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม " },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ],
      load: false,
      errormessage: "กรุณาระบุข้อมูล",
      index: "",
      yearOption: [],
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        year: "",
        month: "",
        branchId: "",
        listType: "",
      },
      optionListType: [
        { nameTh: "แสดงรายบุคคล", id: "U" },
        { nameTh: "แสดงรายทีม", id: "T" },
      ],
      submitform: false,
      contactTypeOption: [],
      branchIdSearch: [],
      localDataBranchId: [],
      dataArr: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      currentSort: "name",
      currentSortDir: "asc",
      fields: [
        {
          key: "loadnApplications",
          label: "จำนวนขอนุมัติ",
          sortable: true,
        },

        {
          key: "prospectCustomers",
          label: "จำนวนลูกค้ามุ่งหวัง",
          sortable: true,
        },
        {
          key: "lostProspectCustomers",
          label: "จำนวนลูกค้ามุ่งหวัง(lost)",
          sortable: true,
        },
        {
          key: "vehicleSales",
          label: "จำนวนการขาย",
          sortable: true,
        },
        {
          key: "branchNameTh",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "show",
          label: "",
          sortable: true,
        },
      ],
    };
  },
  validations: {
    filter: {
      listType: {
        required,
      },
      year: {
        required,
      },
    },
  },
  computed: {
    rowsData() {
      const dataArr = this.rowData;
      return dataArr.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.localData = user.userAccessBranch;

    const localDataBranch = [];
    const localDataBranchId = [];
    // const localBranchIdItem ={};
    this.localData.forEach((item) => {
      localDataBranch.push(item), localDataBranchId.push(item.branchId);
    });
    this.localDataBranch = localDataBranch;
    this.localDataBranchId = localDataBranchId;
  },
  created() {
    // this.getData();
    this.inittahun();
  },
  methods: {
    // pan(data) {
    //   console.log(data);
    //   return data
    // },
    sortedCats: function () {
      return this.cats.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      while (currentYear >= earliestYear) {
        years.push(currentYear);
        currentYear -= 1;
      }
      this.yearOption = years;
    },

    validateForm(branchId) {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.filter.$invalid != true) {
        this.handleSearch(branchId);
      }
    },
    limitText(count) {
      return `and ${count} other countries`;
    },
    handleSearch(branchId) {
      const arr = [];
      branchId.forEach((item) => {
        arr.push(item.branchId);
      });
      this.branchIdArr = arr;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/sale-kpi", {
          params: {
            branchId: this.branchIdArr,
            year: this.filter.year,
            month: this.filter.month ? this.filter.month.id : "",
            listType: this.filter.listType.id,
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
th i.sort-by {
  padding-top: 17px;
  padding-right: 18px;
  position: relative;
}
i.sort-by:before,
i.sort-by:after {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  /* right: -30px; */
  /* top: 50%; */
  position: absolute;
  width: 0;
}
i.sort-by:before {
  border-bottom-color: rgb(129, 129, 129);
  margin-top: -14px;
}
i.sort-by:after {
  border-top-color: rgb(129, 129, 129);
  margin-top: -4px;
}
th {
  cursor: pointer;
}
/* .textconvert {
  width: 70px;
  writing-mode: vertical-lr;
} */
</style>
